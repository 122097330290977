<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3" v-if="!isSeller && !isReviewer">
      리뷰놀이터
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="searchText"
            @keydown.enter="goSearch"
            :placeholder="placeholder"
          />
        </div>
        <router-link to="" @click.native="goSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="rn_my_tit_3 reviewer" v-if="isReviewer">
      <span>리뷰어 홈</span>
      <router-link to="" class="arrow" @click.native="moveBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="searchText"
            @keydown.enter="searchReviewerHome"
            placeholder="리뷰어의 리뷰를 검색하세요"
          />
        </div>
        <router-link to="" @click.native="searchReviewerHome"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="mypage_top" v-if="isSeller">
      우리 제품 리뷰 현황
      <router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <ReviewMainMenu
      v-if="!isSeller && mainMenuList.length > 0"
      :id="getMenuIndex"
      :reviewMenuList="mainMenuList"
      ref="reviewMainMenu"
      @getMenu="getMenu"
    />
    <MainMenu
      v-if="isSeller && mainMenuList.length > 0"
      :id="0"
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
      :isSeller="isSeller"
    />
    <template v-if="showPage">
      <router-view
        @setMemberId="setMemberId"
        :from="from"
        ref="inner_view"
        @searchTextReset="searchTextReset"
      />
    </template>
    <template v-else>
      <div class="ready_menu">
        <p>준비중 입니다.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewMainMenu from "@/components/review/state/ReviewMainMenu";
import MainMenu from "@/components/shop/menu/MainMenu";

export default {
  data() {
    return {
      first: true,
      showPage: true,
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      memberId: null,
      from: this.$route.query.from || null,
      search: this.$route.query.search || "",
      menu: this.$route.query.menu || null,
      searchText: "",
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });

    this.$store.dispatch("review/resetStore");
    this.getMainMenu();
  },
  components: {
    ReviewMainMenu,
    MainMenu,
  },
  computed: {
    mainMenu() {
      if (
        this.$route.path.includes("goods_main") ||
        this.$route.path.includes("reviewer_goods")
      ) {
        return 1;
      }
      if (
        this.$route.path.includes("news_main") ||
        this.$route.path.includes("reviewer_news")
      ) {
        return 3;
      }
      return 1;
    },
    placeholder() {
      if (this.$route.path.includes("goods_main")) {
        return "상품 리뷰를 검색하세요.";
      }
      if (this.$route.path.includes("news_main")) {
        return "뉴스 리뷰를 검색하세요.";
      }
      return "상품 리뷰를 검색하세요.";
    },
    ...mapState("review", ["mainMenuList", "result", "msg"]),
    isSeller() {
      return this.$route.path.includes("seller_main");
    },
    isReviewer() {
      return (
        this.$route.path.includes("reviewer_goods") ||
        this.$route.path.includes("reviewer_news")
      );
    },
    getMenuIndex() {
      if (
        this.$route.path.includes("reviewer_goods") ||
        this.$route.path.includes("goods_main")
      ) {
        const index = this.mainMenuList.findIndex(
          (main) => parseInt(main.seq) === 1
        );
        return index;
      }
      if (
        this.$route.path.includes("reviewer_news") ||
        this.$route.path.includes("news_main")
      ) {
        const index = this.mainMenuList.findIndex(
          (main) => parseInt(main.seq) === 3
        );
        return index;
      }
      return 0;
    },
  },
  methods: {
    searchTextReset() {
      this.searchText = null;
    },
    searchReviewerHome() {
      this.$refs["inner_view"].goSearch(this.searchText);
    },
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(
        `/search/review_search/${this.mainMenu}?search=${this.searchText}`
      );
    },
    moveBack() {
      if (this.from === "myinfo") {
        this.$router.push("/mypage/my_info");
        return false;
      }
      if (this.from === "wish") {
        this.$router.push("/wish");
        return false;
      }
      if (this.from === "search") {
        this.$router.push(`/search/review_search/3?search=${this.search}`);
        return false;
      }
      const rank = this.menu ? `/reviewer_rank/${this.menu}` : "/reviewer_rank";
      this.$router.push(rank);
    },
    isUseMenu(menu, number = null) {
      // console.log("isUseMenu", menu, number, this.category_code);
      const menuInfo = this.mainMenuList.filter(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      // console.log(menuInfo);
      const menuIndex = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(menu)
      );
      // console.log("after", this.category_code);

      return [
        menuInfo[0].use_yn === "Y" ? true : false,
        menuIndex,
        menuInfo[0],
      ];
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      if (this.isMoreMenu) {
        if (this.$route.path.includes("goods_main")) {
          this.$refs.reviewMainMenu.goSlide(this.returnIndex(1));
          return false;
        }
        if (this.$route.path.includes("news_main")) {
          this.$refs.reviewMainMenu.goSlide(this.returnIndex(3));
          return false;
        }
      }
    },
    returnIndex(id) {
      return this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(id)
      );
    },
    getMenu(menu) {
      this.searchText = null;
      if (!this.isSeller) {
        // if (!this.first) {
        const [useYn, menuIndex, menuInfo] = this.isUseMenu(menu, 1);
        if (useYn) {
          this.showPage = true;
          //state listArray 초기화
          this.$store.dispatch("review/listReset");
          if (this.isReviewer) {
            if (parseInt(menu) === 1) {
              if (
                !this.$route.path.includes(
                  `/review/reviewer_goods/${this.memberId}`
                )
              ) {
                this.$router
                  .push(`/review/reviewer_goods/${this.memberId}`)
                  .catch(() => {});
                return false;
              }
              this.$refs.inner_view.reSetView();
            } else if (parseInt(menu) === 3) {
              if (
                !this.$route.path.includes(
                  `/review/reviewer_news/${this.memberId}`
                )
              ) {
                this.$router
                  .push(`/review/reviewer_news/${this.memberId}`)
                  .catch(() => {});
                return false;
              }
              this.$refs.inner_view.reSetView();
            }
          } else {
            if (parseInt(menu) === 1) {
              if (!this.$route.path.includes("/review/goods_main")) {
                this.$router.push("/review/goods_main").catch(() => {});
                return false;
              }
              this.$refs.inner_view.reSetView();
            } else if (parseInt(menu) === 3) {
              if (!this.$route.path.includes("/review/news_main")) {
                this.$router.push("/review/news_main").catch(() => {});
                return false;
              }
              this.$refs.inner_view.reSetView();
            }
          }
        } else {
          this.$store.dispatch("review/listReset");
          // if (!this.isReviewer) {
          //   this.$router.push({ name: "ReviewDumy" }).catch(() => {});
          // }
          this.showPage = false;
        }
        // }
        // this.first = false;
      }
    },
    setMemberId(memberId) {
      this.memberId = memberId;
    },
  },
};
</script>
<style lang="scss" scoped>
.rn_my_wrap {
  .mypage_top {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    background: #eee;
    position: relative;
    padding: 20px 0;
    font-weight: 600;
    a {
      img {
        position: absolute;
        top: 20px;
        left: 15px;
      }
    }
  }
  .rn_my_tit_3 {
    &.reviewer {
      position: relative;
      span {
        margin-left: 40px;
      }
      .arrow {
        img {
          position: absolute;
          top: 20px;
          left: 15px;
        }
      }
    }
  }
  .ready_menu {
    text-align: center;
    font-weight: 600;
    padding: 40px 0px 0px 0px;
    font-size: 14px;
  }
}
</style>
